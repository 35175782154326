import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const UserExtra = () => import('@/entities/user-extra/user-extra.vue');
// prettier-ignore
const UserExtraUpdate = () => import('@/entities/user-extra/user-extra-update.vue');
// prettier-ignore
const UserExtraDetails = () => import('@/entities/user-extra/user-extra-details.vue');
// prettier-ignore
const SensorOrder = () => import('@/entities/sensor-order/sensor-order.vue');
// prettier-ignore
const SensorOrderUpdate = () => import('@/entities/sensor-order/sensor-order-update.vue');
// prettier-ignore
const SensorOrderDetails = () => import('@/entities/sensor-order/sensor-order-details.vue');
// prettier-ignore
const Sensor = () => import('@/entities/sensor/sensor.vue');
// prettier-ignore
const SensorUpdate = () => import('@/entities/sensor/sensor-update.vue');
// prettier-ignore
const SensorDetails = () => import('@/entities/sensor/sensor-details.vue');
// prettier-ignore
const SensorFirmware = () => import('@/entities/sensor-firmware/sensor-firmware.vue');
// prettier-ignore
const SensorFirmwareUpdate = () => import('@/entities/sensor-firmware/sensor-firmware-update.vue');
// prettier-ignore
const SensorFirmwareDetails = () => import('@/entities/sensor-firmware/sensor-firmware-details.vue');
// prettier-ignore
const SensorParameter = () => import('@/entities/sensor-parameter/sensor-parameter.vue');
// prettier-ignore
const SensorParameterUpdate = () => import('@/entities/sensor-parameter/sensor-parameter-update.vue');
// prettier-ignore
const SensorParameterDetails = () => import('@/entities/sensor-parameter/sensor-parameter-details.vue');
// prettier-ignore
const SensorInspectionStandard = () => import('@/entities/sensor-inspection-standard/sensor-inspection-standard.vue');
// prettier-ignore
const SensorInspectionStandardUpdate = () => import('@/entities/sensor-inspection-standard/sensor-inspection-standard-update.vue');
// prettier-ignore
const SensorInspectionStandardDetails = () => import('@/entities/sensor-inspection-standard/sensor-inspection-standard-details.vue');
// prettier-ignore
const SensorInspectionResult = () => import('@/entities/sensor-inspection-result/sensor-inspection-result.vue');
// prettier-ignore
const SensorInspectionResultUpdate = () => import('@/entities/sensor-inspection-result/sensor-inspection-result-update.vue');
// prettier-ignore
const SensorInspectionResultDetails = () => import('@/entities/sensor-inspection-result/sensor-inspection-result-details.vue');
// prettier-ignore
const TboxOrder = () => import('@/entities/tbox-order/tbox-order.vue');
// prettier-ignore
const TboxOrderUpdate = () => import('@/entities/tbox-order/tbox-order-update.vue');
// prettier-ignore
const TboxOrderDetails = () => import('@/entities/tbox-order/tbox-order-details.vue');
// prettier-ignore
const Tbox = () => import('@/entities/tbox/tbox.vue');
// prettier-ignore
const TboxUpdate = () => import('@/entities/tbox/tbox-update.vue');
// prettier-ignore
const TboxDetails = () => import('@/entities/tbox/tbox-details.vue');
// prettier-ignore
const TboxFirmware = () => import('@/entities/tbox-firmware/tbox-firmware.vue');
// prettier-ignore
const TboxFirmwareUpdate = () => import('@/entities/tbox-firmware/tbox-firmware-update.vue');
// prettier-ignore
const TboxFirmwareDetails = () => import('@/entities/tbox-firmware/tbox-firmware-details.vue');
// prettier-ignore
const TboxParameter = () => import('@/entities/tbox-parameter/tbox-parameter.vue');
// prettier-ignore
const TboxParameterUpdate = () => import('@/entities/tbox-parameter/tbox-parameter-update.vue');
// prettier-ignore
const TboxParameterDetails = () => import('@/entities/tbox-parameter/tbox-parameter-details.vue');
// prettier-ignore
const TboxInspectionStandard = () => import('@/entities/tbox-inspection-standard/tbox-inspection-standard.vue');
// prettier-ignore
const TboxInspectionStandardUpdate = () => import('@/entities/tbox-inspection-standard/tbox-inspection-standard-update.vue');
// prettier-ignore
const TboxInspectionStandardDetails = () => import('@/entities/tbox-inspection-standard/tbox-inspection-standard-details.vue');
// prettier-ignore
const TboxInspectionResult = () => import('@/entities/tbox-inspection-result/tbox-inspection-result.vue');
// prettier-ignore
const TboxInspectionResultUpdate = () => import('@/entities/tbox-inspection-result/tbox-inspection-result-update.vue');
// prettier-ignore
const TboxInspectionResultDetails = () => import('@/entities/tbox-inspection-result/tbox-inspection-result-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'user-extra',
      name: 'UserExtra',
      component: UserExtra,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-extra/new',
      name: 'UserExtraCreate',
      component: UserExtraUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-extra/:userExtraId/edit',
      name: 'UserExtraEdit',
      component: UserExtraUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'user-extra/:userExtraId/view',
      name: 'UserExtraView',
      component: UserExtraDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-order',
      name: 'SensorOrder',
      component: SensorOrder,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-order/new',
      name: 'SensorOrderCreate',
      component: SensorOrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-order/:sensorOrderId/edit',
      name: 'SensorOrderEdit',
      component: SensorOrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-order/:sensorOrderId/view',
      name: 'SensorOrderView',
      component: SensorOrderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor',
      name: 'Sensor',
      component: Sensor,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor/new',
      name: 'SensorCreate',
      component: SensorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor/:sensorId/edit',
      name: 'SensorEdit',
      component: SensorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor/:sensorId/view',
      name: 'SensorView',
      component: SensorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-firmware',
      name: 'SensorFirmware',
      component: SensorFirmware,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-firmware/new',
      name: 'SensorFirmwareCreate',
      component: SensorFirmwareUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-firmware/:sensorFirmwareId/edit',
      name: 'SensorFirmwareEdit',
      component: SensorFirmwareUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-firmware/:sensorFirmwareId/view',
      name: 'SensorFirmwareView',
      component: SensorFirmwareDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-parameter',
      name: 'SensorParameter',
      component: SensorParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-parameter/new',
      name: 'SensorParameterCreate',
      component: SensorParameterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-parameter/:sensorParameterId/edit',
      name: 'SensorParameterEdit',
      component: SensorParameterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-parameter/:sensorParameterId/view',
      name: 'SensorParameterView',
      component: SensorParameterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-inspection-standard',
      name: 'SensorInspectionStandard',
      component: SensorInspectionStandard,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-inspection-standard/new',
      name: 'SensorInspectionStandardCreate',
      component: SensorInspectionStandardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-inspection-standard/:sensorInspectionStandardId/edit',
      name: 'SensorInspectionStandardEdit',
      component: SensorInspectionStandardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-inspection-standard/:sensorInspectionStandardId/view',
      name: 'SensorInspectionStandardView',
      component: SensorInspectionStandardDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-inspection-result',
      name: 'SensorInspectionResult',
      component: SensorInspectionResult,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-inspection-result/new',
      name: 'SensorInspectionResultCreate',
      component: SensorInspectionResultUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-inspection-result/:sensorInspectionResultId/edit',
      name: 'SensorInspectionResultEdit',
      component: SensorInspectionResultUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sensor-inspection-result/:sensorInspectionResultId/view',
      name: 'SensorInspectionResultView',
      component: SensorInspectionResultDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-order',
      name: 'TboxOrder',
      component: TboxOrder,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-order/new',
      name: 'TboxOrderCreate',
      component: TboxOrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-order/:tboxOrderId/edit',
      name: 'TboxOrderEdit',
      component: TboxOrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-order/:tboxOrderId/view',
      name: 'TboxOrderView',
      component: TboxOrderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox',
      name: 'Tbox',
      component: Tbox,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox/new',
      name: 'TboxCreate',
      component: TboxUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox/:tboxId/edit',
      name: 'TboxEdit',
      component: TboxUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox/:tboxId/view',
      name: 'TboxView',
      component: TboxDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-firmware',
      name: 'TboxFirmware',
      component: TboxFirmware,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-firmware/new',
      name: 'TboxFirmwareCreate',
      component: TboxFirmwareUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-firmware/:tboxFirmwareId/edit',
      name: 'TboxFirmwareEdit',
      component: TboxFirmwareUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-firmware/:tboxFirmwareId/view',
      name: 'TboxFirmwareView',
      component: TboxFirmwareDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-parameter',
      name: 'TboxParameter',
      component: TboxParameter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-parameter/new',
      name: 'TboxParameterCreate',
      component: TboxParameterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-parameter/:tboxParameterId/edit',
      name: 'TboxParameterEdit',
      component: TboxParameterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-parameter/:tboxParameterId/view',
      name: 'TboxParameterView',
      component: TboxParameterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-inspection-standard',
      name: 'TboxInspectionStandard',
      component: TboxInspectionStandard,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-inspection-standard/new',
      name: 'TboxInspectionStandardCreate',
      component: TboxInspectionStandardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-inspection-standard/:tboxInspectionStandardId/edit',
      name: 'TboxInspectionStandardEdit',
      component: TboxInspectionStandardUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-inspection-standard/:tboxInspectionStandardId/view',
      name: 'TboxInspectionStandardView',
      component: TboxInspectionStandardDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-inspection-result',
      name: 'TboxInspectionResult',
      component: TboxInspectionResult,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-inspection-result/new',
      name: 'TboxInspectionResultCreate',
      component: TboxInspectionResultUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-inspection-result/:tboxInspectionResultId/edit',
      name: 'TboxInspectionResultEdit',
      component: TboxInspectionResultUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tbox-inspection-result/:tboxInspectionResultId/view',
      name: 'TboxInspectionResultView',
      component: TboxInspectionResultDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/user-extra" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.userExtra")),
              },
            },
            [_vm._v("User Extra")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/sensor-order" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.sensorOrder")),
              },
            },
            [_vm._v("Sensor Order")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/sensor" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.sensor")),
              },
            },
            [_vm._v("Sensor")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/sensor-firmware" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.sensorFirmware")
                ),
              },
            },
            [_vm._v("Sensor Firmware")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/sensor-parameter" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.sensorParameter")
                ),
              },
            },
            [_vm._v("Sensor Parameter")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/sensor-inspection-standard" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.sensorInspectionStandard")
                ),
              },
            },
            [_vm._v("Sensor Inspection Standard")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/sensor-inspection-result" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.sensorInspectionResult")
                ),
              },
            },
            [_vm._v("Sensor Inspection Result")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/tbox-order" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.tboxOrder")),
              },
            },
            [_vm._v("Tbox Order")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/tbox" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.tbox")),
              },
            },
            [_vm._v("Tbox")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/tbox-firmware" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.tboxFirmware")
                ),
              },
            },
            [_vm._v("Tbox Firmware")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/tbox-parameter" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.tboxParameter")
                ),
              },
            },
            [_vm._v("Tbox Parameter")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/tbox-inspection-standard" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.tboxInspectionStandard")
                ),
              },
            },
            [_vm._v("Tbox Inspection Standard")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/tbox-inspection-result" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.tboxInspectionResult")
                ),
              },
            },
            [_vm._v("Tbox Inspection Result")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }